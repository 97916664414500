.ribbon-ctn{
    position: absolute;
    top: 0;
    right: 0;
}

.single-viewinfo-parent-ctn{
    padding: 20px 35px;
}
.details-pos-shift-cntr{
    display: flex;
    justify-content: flex-start;
}
.single-viewinfo-parent-ctn p{
    margin: 0;
}
.single-view-left-ctn{
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
}
.single-viewinfo-parent-ctn .justify-space-between{
    gap: 60px;
}

.single-view-info-ctn-left{
    gap: 6px;
    font-weight: 400;
    color: #635b5b;
    width: 140px;
}
.single-view-info-ctn-right{
    gap: 6px;
    font-weight: bold;
}
.shift-bottom-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
}
.shift-bottom-container .shift-info-card:nth-child(1) {
    border-left: 5px solid #0c008b;
  }
.shift-bottom-container .shift-info-card:nth-child(2) {
    border-left: 5px solid #870f01;
  }
.shift-bottom-container .shift-info-card:nth-child(3) {
    border-left: 5px solid #00730f;
  }
.shift-bottom-container .shift-info-card:nth-child(4) {
    border-left: 5px solid #dbac00;
  }
  .shift-bottom-container .shift-info-card:nth-child(5) {
    border-left: 5px solid red;
  }
.shift-info-card{
    background-color: #fff;
    width: calc(25% - 10px);
    border-radius: 2px;
    padding: 30px;
}
.shift-info-card p{
    font-weight: 600;
    margin: 8px;
    font-size: 1.3rem;
}
.shift-info-card .justify-space-between{
    gap: 5px;
}
.margin-right{
    margin-right: 8px;
}
.icon-label-conatiner{
    display: flex;
    margin: 8px;
    align-items: center;
}
.icon-label-conatiner p{
    margin: 0 0 0 12px;
    font-size: 1rem;
}