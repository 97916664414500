.new-altr-cmnt-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 10;
  }
  
  .new-altr-cmnt-modal {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    max-width: 28rem;
    width: 100%;
  }
  
  .new-altr-cmnt-content {
    padding: 1.5rem;
  }
  
  .new-altr-cmnt-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .new-altr-cmnt-title {
    display: flex;
    align-items: center;
  }
  
  .new-altr-cmnt-icon {
    color: #ef4444;
    margin-right: 0.5rem;
  }
  
  .new-altr-cmnt-heading {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
  }
  
  .new-altr-cmnt-close {
    color: #9ca3af;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .new-altr-cmnt-close:hover {
    color: #4b5563;
  }
  
  .new-altr-cmnt-message {
    color: #4b5563;
    margin-bottom: 1.5rem;
  }
  
  .new-altr-cmnt-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
  
  .new-altr-cmnt-button {
    padding:0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    border: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .new-altr-cmnt-button-secondary {
    background-color: #e5e7eb;
    color: #1f2937;
    
  }
  
  .new-altr-cmnt-button-secondary:hover {
    background-color: #d1d5db;
  }
  
  .new-altr-cmnt-button-primary {
    background-color: #ef4444;
    color: white;
    width: 5em;
  }
  
  .new-altr-cmnt-button-primary:hover {
    background-color: #dc2626;
  }

  .new-altr-cmnt-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .new-altr-cmnt-spinner {
    animation: new-altr-cmnt-spin 1s linear infinite;
  }
  
  @keyframes new-altr-cmnt-spin {
    100% { transform: rotate(360deg); }
  }
  
  .new-altr-cmnt-button-primary .new-altr-cmnt-spinner {
    color: white;
  }