.pos-conatiner {
  background-color: #fff;
  padding: 1em;
  margin: 5px;
  position: relative;
  /* height: 90vh; */
}
.pos-top-container {
  display: flex;
  align-items: center;
}
.pos-heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 12px 0 0;
}
.balance-pos {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 12px 0 0;
  color: red;
  width: 200px;
}
.new-order-btn {
  border: 1px solid blue;
  color: blue;
  width: 200px;
  margin: 0 12px;
  border-radius: 4px;
  background: #ececf8;
  height: 40px;
  font-size: 0.9rem;
  cursor: pointer;
}
.pos-clear-btn {
  border: 1px solid #f81e1e;
  color: red;
  width: 200px;
  margin: 0 8px;
  border-radius: 4px;
  background: #fdf6f6;
  height: 40px;
  font-size: 0.9rem;
  cursor: pointer;
}
.pos-hr {
  border: none;
  height: 1px;
  background-color: #bdb9b9;
  margin: 8px 0;
}
.pos-table {
  max-height: 35vh;
  margin: 1% 0;
  border: 1px solid #a8a3a3;
  padding: 0;
}
.pos-table table thead {
  background-color: #d1d9fb !important;
  height: 40px !important;
}
.pos-table table tbody tr td {
  border-bottom: none !important;
  height: 40px !important;
}
.pos-table-round {
  width: 20px;
  height: 20px;
  margin: 0;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  font-size: 0.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pos-components {
  display: flex;
  border: 1px solid #d1d0d0;
  border-radius: 4px;
  justify-content: space-around;
  margin: 12px 0;
  padding: 4px;
  height: 45px;
}
.pos-components p {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
  margin: auto;
  cursor: pointer;
  width: 13%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-right {
  border-right: 1px solid #b9b9b9;
}
.pos-status-btn-container {
  display: flex;
  position: absolute;
  bottom: 10%;
  right: 2%;
}
.pos-status-blue-btn {
  background-color: blue;
  height: 34px;
  width: 80px;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
  border: none;
}
.pos-status-blue-btn img {
  width: 16px;
}
.pos-return-btn {
  background-color: #f81e1e;
}
.pos-print-btn {
  background-color: blue;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 4px;
  height: 35px;
}
.pos-download-btn {
  background-color: #f5c2cf;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: blue;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 4px;
  height: 35px;
}

.shift-table {
}
.shift-table tbody tr td {
  border-bottom: 1px solid #000 !important;
}
.shift-label {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}
.shift-status-active-table {
  background-color: rgb(221, 248, 221);
  color: rgb(5, 168, 5);
  padding: 4px;
  width: fit-content;
  margin: 0 auto;
}
.shift-status-end {
  background-color: rgb(247, 206, 206);
  color: red;
  padding: 4px;
  width: 40%;
  margin: 0 auto;
}

.table-div-container {
  padding-inline: 13px;
  background-color: white;
  margin-top: 5px;
}
.pagi-div {
  display: flex;
  justify-content: end;
}
.btn-pos-wallet-return {
  background-color: #ffe4cf;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
  color: #d77a32;
  width: 100px;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.btn-pos-wallet-return:hover {
  background-color: #f56b3d;
  color: #fff;
}

/* Dialog Container */
.pos-wallet-return-dialog {
  min-width: 400px;
  padding: 20px;
}

/* Header */
.pos-wallet-return-header {
  background-color: #2f4050;
  color: white;
  padding: 12px 20px;
  text-align: center;
  font-weight: 500;
  margin: -20px -20px 20px -20px;
}
.pos-wallet-return-selected-header {
  margin-top: 20px;
}
/* Payment Methods Section */
.pos-wallet-return-methods {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.pos-wallet-return-method {
  background-color: #f2f2f2;
  padding: 12px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}

.pos-wallet-return-method.selected {
  background-color: #e6e6e6;
}

.pos-wallet-return-method:hover {
  background-color: #e6e6e6;
}

/* Selected Payment Section */
.pos-wallet-return-selected {
  margin: 20px 0;
  height: 100px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  padding-right: 10px;
  
}

.pos-wallet-return-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  padding: 12px 15px;
  margin-bottom: 10px;
}

.pos-wallet-return-remove {
  background-color: black;
  color: white;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

/* Card Input */
.pos-wallet-return-card-input {
  border: 1px solid #ddd;
  padding: 8px;
  width: 150px;
  margin-right: 10px;
}

/* Action Buttons */
.pos-wallet-return-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.pos-wallet-return-button {
  flex: 1;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.pos-wallet-return-button:hover {
  opacity: 0.9;
}

.pos-wallet-return-button.return {
  background-color: #28a745;
  color: white;
}

.pos-wallet-return-button.cancel {
  background-color: #dc3545;
  color: white;
}

/* Card Payment Row */
.pos-wallet-return-card {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pos-wallet-return-card img {
  width: 40px;
  height: auto;
}

/* Amount Display */
.pos-wallet-return-amount {
  font-weight: 500;
}

/* Dialog Overlay */
.MuiDialog-paper {
  border-radius: 0 !important;
}
