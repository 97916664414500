.new-order-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.new-order-top-left{
    display: flex;
    /* width: 20%; */
    flex-basis: 50%;
}
.pos-new-order-btn {
    padding: 0.8em;
    background-color: #f6f6fd;
    border: 1px solid #0516f8;
    color: #0516f8;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }
  
  .pos-new-order-btn:hover {
    background-color: #0516f8;
    color: #f6f6fd;
  }
  
  .pos-new-order-btn:active {
    transform: scale(0.95);
  }
.new-order-top-left-home{
    border: 1px solid #000;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background-color: #f5f3f3;
    cursor: pointer;
}
.new-order-top-left-p{
    margin: 0 9px;
    color: #000;
    font-size: medium;
    font-weight: bold;
}
.new-order-top-right{
    display: flex;
    /* width: 80%; */
    justify-content: space-between;
}
.new-order-top-right-btn{
    display: flex;
}
.new-order-top-right-btn button{
    width: 100px;
}
.new-order-top-right-radio{
    display: flex;
    align-items: center;
    margin: 0 20px;
    width: 64%;
    overflow-x: auto;
}
.new-order-top-right-radio::-webkit-scrollbar {
    height: 3px; /* Adjust the width of the scrollbar */
    background-color: #f1f1f1;
    cursor: pointer;
    border-radius: 2px;
}

.new-order-top-right-radio::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    cursor: pointer;
}
.new-order-top-right-radio-single{
    margin: 0 12px;
    accent-color: black;
    display: flex;
    align-items: center;
}
.new-order-top-right-radio-single input[type="radio"]{
    accent-color: black;
    cursor: pointer;
}
.new-order-top-right-radio-single label{
    font-size: 0.8rem;
    cursor: pointer;
    margin: 4px 0 0 4px;
    font-weight: 600;
}
.new-order-second-container{
    display: flex;
    margin: 12px 0;
}
.new-order-second-left,
.new-order-second-right {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 20vh;
}

.new-order-second-left-input{
    width: 45%;
    margin: 8px;
}
.new-order-second-right-input{
    width: 22%;
    margin: 8px;
}
.new-order-tax-input div.MuiOutlinedInput-root{
    height: 100% !important;
}
/* .new-order-second-right-input div.MuiOutlinedInput-root{
   height: 100% !important;
} */
.new-order-second-right-add-btn{
    width: 22%;
    margin: 8px;
    height: 40px;
    background-color: green;
    color: #fff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
