.generate-qr-barcode-top-container{
  background-color: #fff;
  padding:4% 2%;
  display: flex;
  flex-direction: column;
}
.generate-qr-barcode-top-container-input-section{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.generate-qr-barcode-second-container{
    margin: 1% 0;
    display: flex;
}
.generate-qr-barcode-second-left{
    background-color: #fff;
    padding: 2% 0;
    width: 40%;
    margin: 0 1% 0 0;
    display: flex;
    flex-direction: column;
}
.generate-qr-barcode-second-right{
    background-color: #fff;
    padding: 2%;
    width: 60%;
    display: flex;
    flex-direction: column;
   
}
.generate-qr-barcode-second-left-single-container{
    display: flex;
    align-items: center;
    margin: 1% 4%;
}
.generate-qr-barcode-second-left-single-container h5{
    width: 20%;
    margin: 0;
}
.generate-qr-barcode-second-left-single-container span{
    width: 8%;
}
.generate-qr-barcode-second-left-single-container p{
    margin: 0;
    font-size: 0.8rem;
}
.generate-qr-barcode-second-left-single-container img{
    width: 80px;
    height:80px;
}
.generate-qr-barcode-second-left-single-dimension-container{
    margin:4%;
}
.generate-qr-barcode-second-left-single-dimension-container h5{
   margin: 0;
}
.generate-qr-barcode-second-left-single-dimension-input-container{
    display: flex;
    margin: 2% 0;
}
.qr-bar-size-input{
    width: 14% !important;
}
.qr-bar-size-price{
    width: 20% !important;
}
.qr-bar-size-mrp{
    width: 16% !important;
}
.generate-qr-barcode-second-right button{
    margin: auto;
    border: none;
    height: 12%;
    width: 40%;
    cursor: pointer;
    font-weight: 600;
}
.generate-qr-barcode-second-right-top-container{
    display: flex;
    justify-content: space-between;
}

.generate-qr-barcode-second-right-bottom-left-container{
  display: flex;
  width: 50%;
  align-items: center;
  gap: 5px;
  /* justify-content: space-between; */
}

.generate-qr-dimension-container{
    width: 50% !important;
}

.generate-qr-dimension-container{
    width: 60% !important;
}

.generate-qr-dimension-container p{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.generate-qr-dimension-container-print-btn{
    display: flex;
    flex-direction: column;
}
.gr-bar-code-print{
    /* width: 26% !important; */
    height:34px !important;
    margin: 8% 2% 0 2% !important
   
}
.generate-qr-barcode-second-right-bottom-container .print-qr-container{
    width: 32px;
}
.print-qr-container .print-icon{
    color: green;
}
.generate-qr-barcode-second-right-top-radio-container{
    display: flex;
    align-items: center;
    width: 38%;
    justify-content: space-between;
}
.generate-qr-barcode-second-right-single-radio-container{
    margin-top: 11%;
    display: flex;
    align-items: center;
}
.generate-qr-barcode-second-right-single-radio-container input[type="radio"]{
    accent-color: green;
    margin: 0;
    cursor: pointer;
}
.generate-qr-barcode-second-right-single-radio-container label{
    font-size: 0.8rem;  
    font-weight: 500;
    margin-left: 8px;
    cursor: pointer;
}
.generate-qr-code-image-container{
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin: 6% 0;
    /* height: 152px; */
    align-items: center;
    
}
.generate-qr-code-image-container img{
    width: 36%;
    margin: auto;
}
.generate-qr-code-image-container span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}
.generate-qr-barcode-image-container{
    width: 50% !important;
}
.generate-qr-barcode-second-right-bottom-container{
    display: flex;
    justify-content: space-between;
}
.generate-qr-barcode-bottom-checkbox-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 48%;
}
.generate-qr-barcode-bottom-checkbox-single-container{
    display: flex;
    margin: 1% 0;
    align-items: center;
    justify-content: space-between
}
.generate-qr-barcode-bottom-checkbox-single-container input[type="checkbox"]{
    accent-color: black;
    cursor: pointer;
}
.generate-qr-barcode-bottom-checkbox-single-container p{
    font-size: 0.8rem;
}
.generate-qr-barcode-bottom-checkbox-single-container .generate-qrcode-checkbox-input{
    width: 66%;
    height: 32px;
}
.generate-qr-code-image-print-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2%;
    justify-content: center;
}
.generate-qr-code-image-print{
    /* height: auto;
    max-width: 100%;
    width: 100%; */
width:100px;
/* margin:12px; */
}
.generate-bar-code-image-print{
    /* width:130px; */
   /* margin:12px; */
   width:214px
}
.generate-bar-code-image-print-remove-bottom-margin{
   width:100px;
   /* margin:12px 12px 0 12px; */
}



.generate-qr-code-print-new-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
   
}
.qr-bar-code-single{
    margin: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
}
.qr-prod-name-print{
    font-size: 0.6rem;
    margin: 0;
    font-weight: 500;
}

.print-single-code{
    height: 90vh;
    display: flex;
    align-items: center;
}
  .print-single-code .qr-bar-code-single {
    height: 100%;
    width: 100%;
  } 
 
@media print {
    /* .print-single-code {
      display: block;
      page-break-before: always; 
    }
  
    .print-single-code .qr-bar-code-single {
      page-break-inside: avoid;
    } */
    .qr-bar-code-single{
        width: 100%; 
        margin: 0 auto; 
        padding: 20px;
        box-sizing: border-box;
    }
    .barcode {
        width: 100%; 
        height: 10px;
        display: block;
        margin-bottom: 20px;
      }
  }