.cst-page-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .cst-page-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .cst-page-item {
    margin: 0 5px;
  }
  
  .cst-page-link {
    display: inline-block;
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #333;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cst-page-link:hover {
    background-color: #e0e0e0;
  }
  
  .cst-page-active .cst-page-link {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .cst-page-link:disabled {
    background-color: #f0f0f0;
    color: #999;
    cursor: not-allowed;
  }
  