.invoice-table-container{
    background-color: white; 
   padding: 1px;
   width: 99%;
   margin-left: 5px;
   margin-top: 9px;
}

.invoice-table-container table{
width: 100%;
}
.invoice-table-container th{
background-color: #002995;
/* background-color: #1d1da7; */
color: white;
font-size: smaller;
/* width: 15%; */
text-align: start;
padding-left: 12px;
}

/* .invoice-table-container tr{
cursor: pointer;
} */
.invoice-table-container td{
background-color: #ededed;
text-align: start;
padding-left: 12px;
cursor: pointer;
}

.table-container::-webkit-scrollbar {
width: 5px; 
}

.table-container::-webkit-scrollbar-track {
background-color: #ffffff; 
}

.table-container::-webkit-scrollbar-thumb {
background-color: #888;
}

.invoice-main-div{
overflow: auto;
max-height: 65vh;
position: relative;
}
.invoice-main-div thead{
position: sticky;
top: 0px;
background-color: #edf2f9;
height: 36px;
z-index: 2;
}
.invoice-main-div::-webkit-scrollbar {
width: 5px; 
}

.invoice-main-div::-webkit-scrollbar-track {
background-color: #ffffff; 
}

.invoice-main-div::-webkit-scrollbar-thumb {
background-color: #888;
}

.invoice-button-ctn{
    border: 2px solid #d7d7d7 ;
    border-radius: 2px;
    background-color: #d7d7d7; 
    padding: 1px; 
    cursor: pointer;
}
.non-selected-btn{
    background-color: #d7d7d7;
    border:none;
    padding: 11px 20px;
    cursor: pointer;
}
.selected-button {
    background-color: #370c0c;
    color: white;
    border: 2px solid #370c0c;
    border-radius: 2px;
    padding: 11px 20px;
    margin-right: 10px;
    cursor: pointer;
}

.selected-button:last-child {
    margin-right: 0;
}

.selected-button:hover {
    background-color: #370c0c; 
}

.selected-button:focus {
    outline: none;
}

.selected-button:active {
    transform: translateY(1px); 
}

.branch-head{
    color: #002995;
}
.grand-row th{
    background-color: #94b5fa;
    color: black;
}
.invoice-sub-head td{
    background-color:#002995;
    color: white;
    text-align: start;
    font-weight: 500;
    padding-left: 18px;
    width: auto;
}
