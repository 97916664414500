.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 10;
}

.popup-content {
  background-color: white;
  /* border-radius: 0.5rem; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 80rem;
}

.popup-header {
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  padding: 1rem;
  /* border-bottom: 1px solid #e5e5e5; */
}
.cutter-table input {
    border: none;
}
.popup-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #000000;
}
.popup-header h2 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.update-button {
  background-color: #0000ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  border: none;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6b7280;
}

.popup-body {
  padding: 1rem;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  margin-bottom: 1rem;
  padding-top: 2em;
  /* border-top: 1px solid #e5e5e5; */
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.detail-label {
  font-weight: 500;
}

/* .tabs {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 1rem;
  } */

.tab-button {
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  border-bottom: 5px solid #f3f4f6;
  cursor: pointer;
  background-color: #f3f4f6;
 
}

.active-tabs {
  border-bottom: 5px solid #000000;
  background-color: #615d5d;
  color: #ffff;
} 
/* .job-pop-input-sm{
  width: 40px;
}  
.job-pop-input-md{
  width: 80px;
}  
.job-pop-input-lg{
  width: 130px;
}   */

.job-cmp-cntr tbody tr td{
 /* width: 7em;  */ 
}
.cutting-slip-table-cntr{
    height: 20em;
    overflow: auto;
    position: relative; 
}

.cutter-table {
  width: 100%;
  border-collapse: collapse;
 
}
.cutter-table thead {
position: sticky;
top: 0;
z-index: 10;
}

.cutter-table th,
.cutter-table td {
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
  
}

.cutter-table th {
  background-color: #f3f4f6;
  font-weight:600 ;

}

.delete-button {
  background: none;
  border: none;
  color: #ef4444;
  cursor: pointer;
}

.add-line-button {
  margin-top: 1rem;
  color: #3b82f6;
  background: none;
  border: none;
  cursor: pointer;
}


/* back button  */
.button-back-pos {
  display: block;
  position: relative;
  width: 45px;
  height: 45px;
  margin: 0;
  overflow: hidden;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}

.button-back-pos:before,
.button-back-pos:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 7px;
}

.button-back-pos:before {
  border: 2px solid #000000;
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-back-pos:after {
  border: 2px solid #96daf0;
  transform: scale(1.3);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.button-back-pos:hover:before,
.button-back-pos:focus:before {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.button-back-pos:hover:after,
.button-back-pos:focus:after {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.4s cubic-bezier(0.77, 0, 0.175, 1) 80ms,
    transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) 80ms;
}

.button-box-pos-bk {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.button-elem-pos-bk {
  display: block;
  width: 10px;
  height: 15px;
  margin: 17px 18px 0 18px;
  transform: rotate(180deg);
  fill: #000000;
}

.button-back-pos:hover .button-box-pos-bk,
.button-back-pos:focus .button-box-pos-bk {
  transition: 0.4s;
  transform: translateX(-45px);
}

.new-alt-pup-dialog {
  max-height: 90vh;
  margin: 5vh auto;
}

.new-alt-pup-overlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5em;
}

.new-alt-pup-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.new-alt-pup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e0e0e0;
}

.new-alt-pup-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.new-alt-pup-header-buttons {
  display: flex;
  gap: 0.75rem;
}

.new-alt-pup-update-button,
.new-alt-pup-close-button {
  padding: 0.4rem 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  width: 5em;
}

.new-alt-pup-update-button {
  width: 5em;
  background-color: #4caf50;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-alt-pup-close-button {
background-color: #ca3c37;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-alt-pup-body {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
}

.new-alt-pup-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
}

.new-alt-pup-tabs {
  display: flex;
  gap: 0.4rem;
  margin-bottom: 0.75rem;
}

.new-alt-pup-tab-button {
  padding: 0.4rem 0.75rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 0.875rem;
}

.new-alt-pup-active-tab {
  background-color: #e0e0e0;
  font-weight: bold;
}

.new-alt-pup-table-container {
  flex: 1;
  overflow: hidden;
  margin-bottom: 0.75rem;
}

.new-alt-pup-table-scroll {
  max-height: 300px;
  overflow-y: auto;
}

.new-alt-pup-table {
  width: 100%;
  border-collapse: collapse;
}

.new-alt-pup-table th,
.new-alt-pup-table td {
  padding: 0.4rem;
  text-align: left;
  font-size: 0.8125rem;
}

.new-alt-pup-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.new-alt-pup-add-line-button {
  padding: 0.4rem 0.75rem;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  align-self: flex-start;
}

/* Style for all input fields, select, datepicker, and react-select */
.new-alt-pup-content input,
.new-alt-pup-content select,
.new-alt-pup-content .react-datepicker-wrapper,
.new-alt-pup-content .react-select__control {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  height: 32px !important; /* Set consistent height */
  font-size: 0.8125rem !important;
  padding: 0 0.4rem !important;
}

/* Ensure the input inside react-datepicker-wrapper has the same height */
.new-alt-pup-content .react-datepicker-wrapper input {
  height: 100% !important;
}

/* Style for react-select to match other inputs */
.new-alt-pup-content .react-select__control {
  min-height: 32px !important;
  height: 32px !important;
}

.new-alt-pup-content .react-select__value-container {
  padding: 0 0.4rem !important;
}

.new-alt-pup-content .react-select__indicators {
  height: 32px !important;
}

/* Remove focus styles */
.new-alt-pup-content input:focus,
.new-alt-pup-content select:focus,
.new-alt-pup-content .react-datepicker-wrapper:focus-within,
.new-alt-pup-content .react-select__control--is-focused {
  background-color: transparent !important;
}

/* Remove table cell borders */
.new-alt-pup-table td,
.new-alt-pup-table th {
  border: none;
}

/* Add subtle bottom border to table rows for separation */
.new-alt-pup-table tr {
  border-bottom: 1px solid #f0f0f0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .new-alt-pup-details-grid {
    grid-template-columns: 1fr;
  }

  .new-alt-pup-tabs {
    flex-wrap: wrap;
  }
}


 /* MessageComponent.css */
.new-err-msg-container {
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  font-family: Arial, sans-serif;
}

.new-err-msg-error {
  background-color: #ffebee;
  border: 1px solid #f44336;
  color: #d32f2f;
}

.new-err-msg-success {
  background-color: #e8f5e9;
  border: 1px solid #4caf50;
  color: #2e7d32;
}

.new-err-msg-text {
  margin: 0;
  font-size: 14px;
}

/* back button  */
.custom-back-button {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  padding: 10px 20px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
  color: #333;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.custom-back-button:hover {
  background-color: #e0e0e0;
  /* transform: scale(1.05); */
}

