.add-customer-dialog{
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    gap: 20px;
    width: 500px;
}
.add-customer-dialog .MuiOutlinedInput-root{
    padding: 0!important;
  }
  .add-customer-dialog .head{
   justify-content: start;
   gap: 20px;
   font-weight: 600;
  }
  .add-customer-dialog .head .icon{
    background-color: #ade8c2;
    border-radius: 50%;
    padding: 8px;
    padding-bottom: 10px;
  }
  .add-customer-dialog .head img{
    width: 25px;
    height: 25px;
  }
  .add-customer-dialog .input-ctn{
    width: 100%;
    border: 1px solid #ebeefe;
    display: flex;
    position: relative;
  }
  .add-customer-dialog .input-ctn button.btn{
    background-color: #dde1f3;
    color: #000;
    min-width: 100px;
    margin: 0;
    border-radius: 0;
  }
  .add-customer-dialog .input-ctn button:hover{
    background-color: #7c89c3;
    color: #504f4f;
  }
  .add-customer-dialog .input-ctn input{
   border: none;
   flex: 1;
   text-align: end;
   padding-right: 15px;
  }
  .add-customer-dialog-btn{
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .add-customer-dialog-btn .doc-validation-alert{
    color:green;
    cursor:pointer;
    top:-16px;
    margin: 0;
  }
  .add-customer-dialog-btn .btn-secondary-outlined{
    width: 70px;
    height: 35px;
    font-size: 0.85rem;
  }
  .add-customer-dialog-btn .btn-primary{
    height: 35px;
    width: 120px;
    font-size: 0.85rem;
    background-color: #16a90a;
  }
  .customer-popup-autocomplete{
    width: 100%;
    height: 40px;
  }
  .customer-popup-autocomplete input{
   text-align: start!important;
  }
  .customer-popup-autocomplete .MuiOutlinedInput-root{
    padding: 0 9px;
  }
  .customer-popup-autocomplete fieldset{
    border: none;
  }
  .add-customer-plus-btn{
    background-color: #0f9715;
    border: none;
    width: 30px;
    height: 30px;
    margin-left: 4px;
    color: #fff;
    font-size: 1.3rem;
    border-radius: 3px;
    cursor: pointer;
  }