.main-container{
    background-color: white;
    padding: 20px;
    width: 98%;
    margin-left: 8px;
    margin-top: 12px;
}
.first-div-container{
    position: relative;
    display: flex;
}
.post-ribbon{
    position: absolute;
    right: -21px;
    top: -21px;
}
.printer-div{
    font-size: x-large;
  
}
.line {
    border: 1px solid #bfb8b8;
    margin: 0 10px;
    width: 94%;
}
.second-main-container{
    display: flex;
    padding: 1% 8%;
    /* padding-inline: 122px; */
    width: 100%;
    justify-content: space-between;
   
}
.left-side-div{
    width: 53%;
}
.left-side-div img{
    height: 37px;
}
.left-side-div p{
   margin: 0px;
   font-size: small;
}
.right-side-div{
    position: relative;
    width: 50%;
  
}
.date-container-single{
    margin-right: 10px;
}

.right-side-div p{
    text-align: end;
    margin: 0;
    font-size: small;
    font-weight: 900;}
 .right-side-div h1{
     margin: 0px;
     text-align: end;
      font-weight: 400;
      font-size: x-large;
    }
.vendor-head{
    margin: 0;
    font-size: small;
    margin-top: 23px;
}
.Table-data td{
   text-align: -webkit-center;
  
}
.Table-data td p{
    width: 33px;
 }
.tax{
    border: 1px solid;
    width: 25px;
}
.date-div{
  
    justify-content: space-around;
    width: 192px;
    position: absolute;
    bottom: 0;
    right: 0px;
}
.date-div p{
    font-weight: 400;
    font-size: small;
}
.Table-data{
    width: 85%;    
    max-height: 51vh;
    overflow-y: auto;
}

.Table-container{
    text-align: -webkit-center;
}
.Table-data table{
    width: 100%;
    border-spacing: 0px;
}
.Table-data th{
    font-size: small;}
.Table-data tr{
    text-align: center;
    height: 50px;
    font-size: small;
}
/* Add alternating row colors */

.last-total-div{
    justify-content: end;
    display: flex;
    background-color: rgb(234, 238, 249);
    /* padding: 45px; */
    padding: 19px 34px;
}
.sub-total{
    margin-right: 63px;
    text-align-last: end;
}
.Table-datas td{
    text-align: -webkit-center;
   
 }
 .Table-datas td p{
     width: 33px;
  }
.Table-data::-webkit-scrollbar{
    width: 4px; 
}
.Table-data::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}
.Table-datas{
    padding: 18px 15px;
}
.Table-datas table{
    width: 100%;
    border-spacing: 0px;
}
.Table-datas tr{
    text-align: center;
    height: 32px;
    font-size: small;
}
.Table-data table thead{
    position: sticky;
    top: -6px;
    z-index: 2;
}
.terms h1{
    padding: 5px 52px;
    font-size: medium;
}
.lines{
    border: 1px solid #313131;
    width: 88%;
}
  
.sub-total h1{
    font-size: small;
    margin: 0;
}
.sub-total p{
    font-size: small;
    margin: 0px;
}
.sub-total-amount{
    text-align-last: end;
}
.sub-total-amount p{
    font-size: small;
    margin: 0px;
}

.sub-total-amount h1{
    font-size: small;
    margin: 0;
}
.line-2{border: 1px solid #c1c0c0;
    /* margin: 0 10px; */
    width: 134%;}

    .line-3{
        border: 1px solid #c1c0c0;
        /* margin: 0 10px; */
        width: 72px;
        margin-left: -28px;
    }