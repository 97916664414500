.accounting-container {
  display: flex;
  gap: 1em;
  /* justify-content: center; */
}
.accounting-side-menu {
  flex: 0 0 25%;
  border-radius: 5px;
  background-color: #fff !important;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 100vh;
  font-weight: 800 !important;
}
.accounting-voucher-menu {
  flex: 0 0 70%;
}
.accounting-side-menu ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  justify-content: center;
}
.accounting-side-menu li {
  background-color: lightblue;
  height: 2em;
  display: flex;
  align-items: center;
}

.accounting-container .css-tlelie-MuiListItemText-root {
  margin: 8px 8px;
}
.accounting-side-menu .isactive {
  background-color: #ebeefe;
  color: #002afc;
}
.accounting-side-menu .isactive:hover {
  background-color: #ebeefe;
}
.accounting-voucher-menu > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 0;
}
.accounting-voucher-menu > div h3 {
  flex: 100%;
}
.accounting-input-field {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  margin-block-end: 2em;
}

.accounting-voucher-menu > div .accounting-voucher-menu-sections {
  background-color: #ffffff;
  /* border-radius: 5px; */
  padding: 10px 25px;
  /* height: 20em; */
  flex: 100%;
}

.accounting-input-field .global-single-input {
  width: 28%;
}
.accounting-voucher-menu-sections .global-single-input p {
  white-space: nowrap;
}
.accounting-voucher-menu-sections .voucher-add {
  display: flex;
  justify-content: flex-end;
  padding: 1%;
  margin-block-end: 2em;
}

.voucher-add {
  display: flex;
  justify-content: flex-end;
  padding: 1%;
  gap: 1%;
}
.voucher-add button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 5px;
  height: 3em;
  width: 3em;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.voucher-add .add-button {
  background-color: #00b112;
}
.voucher-add .delete-button {
  background-color: #e31212;
}
.voucher-add .delete-button .delete-icon {
  color: #fff !important;
}

.accounting-voucher-menu-sections .data-table-row-head td {
  background-color: #edeef0 !important;
  color: black;
}
.accounting-voucher-menu-sections > div {
  padding: 5px 0;
  margin-block-start: 2em;
}

.button-container {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  background-color: #ebebeb;
  padding: 1% !important;
}
.button-container button {
  height: 3em;
  width: 8em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}

.button-container > button {
  justify-self: start;
  background-color: #f38a00;
  color: #fff;
  white-space: nowrap;
}
.button-container > div {
  display: flex;
  gap: 1em;
}
.button-container > div :nth-child(1) {
  border: 1px solid black;
  white-space: nowrap;
}
.button-container > div :nth-child(2) {
  background-color: black;
  color: white;
  white-space: nowrap;
}
.button-container :nth-child(2) {
  justify-self: end;
}

.accounting-payment-voucher-input-field {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* gap: 30px 0; */
  margin-block-end: 2.5em;
}
.accounting-payment-voucher-input-field .global-single-input {
  width: 20%;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #002afc !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #002afc !important ;
}
.purchase-voucher-input-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em 2em;
}
.box {
  background-color: lightgray;
  padding: 10px;
}
.purchase-voucher-input-container .global-single-input {
  width: 100%;
}

.purchase-voucher .css-19midj6 {
  padding: 0px !important;
}

.gst-input-field > div {
  /* width: 80%; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}
.gst-input-field > div p {
  font-weight: 600;
}
.item-invoice-input-container-3 .gst-input-field > div h5 {
  width: 200px;
}

.gst-input-field > div .global-single-input {
  width: 100%;
}

.item-invoice-input-container .global-single-input {
  width: 30%;
}

.purchase-return-voucher .global-single-input {
  width: 30%;
}
.purchase-return-inputs .global-single-input input {
  background-color: #ffff;
}
.purchase-return-voucher .gst-input-field > div h5,
h4 {
  width: 88%;
}
.purchase-return-voucher .gst-input-field > div h4 {
  white-space: nowrap;
  width: 110px;
}
.gst-input-box h5 {
  width: 10px !important;
  margin-right: 40px !important;
}
.depreciation-voucher-input .global-single-input {
  width: 100%;
}

/* button */

.accounting-container .container {
  width: 100%;
  display: flex;
  align-items: center;
}

.accounting-container .container .voucher-add {
  margin-block-end: 0em;
  margin-block-start: 1.5em;
  justify-content: flex-start;
  gap: 10px;
}

.accounting-container .container .voucher-add .add-button,
.delete-button {
  height: 3em;
  width: 3em;
}

.accounting-container .container .voucher-add .add-button {
  background: #00b112;
}

.accounting-container .container .voucher-add .delete-button {
  background: #e33d31;
}

.item-purchase-input-data-container {
  display: flex;
  gap: 5%;
  justify-content: space-around;
}
.item-purchase-input-data-container > div {
  display: flex;
  gap: 5%;
}
.item-purchase-input-data-container > div .gst-input-field > div h5 {
  width: 50%;
}
.item-purchase-input-data-container
  > div
  .gst-input-field
  > div
  .global-single-input
  input {
  background-color: #ffff;
}
.opening-container{
  height: 86vh;
   background-color: white;
   /* display: flex;
   justify-content: center; 
   align-items: center;  */
   
 }
 .image-div{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
