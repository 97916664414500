.container-alt229 {
  font-family: Arial, sans-serif;
  /* max-width: 1200px; */
  margin: 1em;
  padding: 20px;
  background-color: white;
}

.header-alt229 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.left-icons-alt229 {
  display: flex;
  align-items: center;
}

.icon-alt229 {
  margin-right: 10px;
}

.alteration-text-alt229 {
  font-weight: bold;
  color: green;
}

.print-button-alt229 {
  display: flex;
  align-items: center;
  background-color: blue;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 1em;
}

.help-icon-alt229 {
  color: red;
}

.order-info-alt229 {
  /* background-color: #f0f0f0; */
  padding: 15px;
  margin-bottom: 20px;
}
.ord-top-cntr-alt229 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-number-alt229 {
  margin: 0;
  display: inline-block;
  margin-right: 10px;
}

.booking-badge-alt229 {
 
 font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}
.booking-badge-alt229.booking-alt {
  background-color: #cce5ff; /* Faded blue */
  color: #007BFF; /* Original blue text */
}

.booking-badge-alt229.pending-alt {
  background-color: #fff8cc; /* Faded yellow */
  color: #e0b60d; /* Original yellow text */
}

.booking-badge-alt229.completed-alt {
  background-color: #d4edda; /* Faded green */
  color: #28A745; /* Original green text */
}

.booking-badge-alt229.cancel-alt {
  background-color: #f8d7da; /* Faded red */
  color: #DC3545; /* Original red text */
}


.order-details-alt229 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.left-details-alt229,
.right-details-alt229 {
  flex: 1;
}

.tabs-alt229 {
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
}

.tab-alt229 {
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-active-alt229 {
  background-color: #ddd;
  font-weight: bold;
}

/* .tab-content-alt229 {
    margin-bottom: 20px;
    height: 40vh;
    overflow-y: scroll;
    position: relative;

  }
  
  .table-alt229 {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

  }

  .table-alt229 thead {
    top: 0;
        position: sticky;
    }
    .table-alt229 thead th{
      border: 1px solid black;

  } */
.tab-content-alt229 {
  margin-bottom: 20px;
  /* height: 40vh; */
  overflow-y: auto;
  position: relative;
}

.table-alt229 {
  table-layout: fixed;
  width: 100%;
  /* border-collapse: collapse; */
  margin-bottom: 20px;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.table-alt229 thead th {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #f0f0f0;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  font-size: 13px;
  font-weight: 700;
}
.table-alt229 thead :nth-child(1) th {
  top: 0;
  border-bottom: 0px solid #dddddd;
}
.table-alt229 thead :nth-child(2) th {
  top: 4em;
}

/* create alteration component style  */
.aof-wrapper {
  padding: 1.5em;
}
.aof-container {
  font-family: Arial, sans-serif;
  background-color: #ffff;
  height: 90vh;
  padding: 2em;
}

.aof-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.aof-left-side {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.aof-home-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  size: 25px;
}

.aof-header-buttons {
  display: flex;
  align-items: center;

}

.aof-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;
}

.aof-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.aof-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.aof-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.aof-switch input:checked + .aof-slider {
  background-color: #2196f3;
}

.aof-switch input:checked + .aof-slider:before {
  transform: translateX(26px);
}

.aof-slider.aof-round {
  border-radius: 34px;
}

.aof-slider.aof-round:before {
  border-radius: 50%;
}

.aof-btn {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  
  transition: all 0.3s ease-in-out;
  
}

.aof-btn-payment {
  background-color: #4caf50;
  color: white;
  width: 8em;

}
.aof-btn-payment.p-loading-btn{
  width: 4em;
}

.aof-btn-place-order {
  background-color: #008cba;
  color: white;
}

/* disable button style */
.aof-btn-disabled{
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.aof-btn-clear {
  background-color: #f44336;
  color: white;
}

.aof-form-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.aof-input,
.aof-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.aof-btn-add {
  background-color: #4caf50;
  color: white;
  height: 40px;
}
.aof-table-wrapper {
  height: 50vh;
  min-height: 50vh;
  overflow: auto;
  /* margin-top: 1em; */
}
.aof-order-table {
  width: 100%;
  /* border-collapse: collapse; */
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}
.pos-tb-action{
  display: flex;
  justify-content: center;
}
.aof-order-table thead{
  top: 0;
  position: sticky;
}
.aof-order-table th{
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #cedbff;
  padding: 0.5em;
}
.aof-order-table .pos-b2 {
  background-color: #f2f2f2; /* Change this to your desired color */
}
.aof-order-table td{
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.aof-order-table th:first-child,
.aof-order-table td:first-child {
  border-left: 1px solid #ddd;
}


.aof-order-table tfoot {
  inset-block-end: 0;

}
.aof-order-table tfoot td {
  background-color: #f8f9fa;

  position: sticky;
  bottom: 0;
  z-index: 2;
  font-weight: bold;
  /* border-top: 1px solid #ddd; */
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: #edecec;
}

/* .aof-order-table tfoot td{
  border-bottom: 1px solid #ddd;
} */
/* .aof-order-table thead th {
  position: sticky;
  top: 0;
  background-color: #edecec;
} */

/* .aof-order-table th,
.aof-order-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.aof-order-table th {
  background-color: #f2f2f2;
} */

.aof-btn-edit,
.aof-btn-delete {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: transparent;
}

.aof-btn-edit {
  color: #1100ff;
}

.aof-btn-delete {
  color: #f44336;
  /* color: white; */
}
/* --- */

@media screen and (min-width: 1450px) {
  .table-alt229 thead tr:nth-child(2) th {
    top: 2.5em;
  }
}

.sub-head-alt229 {
  background-color: #e1e9ff !important;
}

.table-alt229 tbody td {
  font-size: 12px;
  /* border: 1px solid #dddddd; */
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.table-alt229 th:first-child,
.table-alt229 td:first-child {
  border-left: 1px solid #dddddd;
}

.table-alt229 th,
.table-alt229 td {
  padding: 8px;
  text-align: left;
}

.table-alt229 thead {
  background-color: #f0f0f0;
}

.table-alt229 tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.summary-alt229 {
  margin-top: 20px;
}

.summary-row-alt229 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.total-alt229 {
  font-weight: bold;
}

.amount-due-alt229 {
  color: red;
  font-weight: bold;
}

@media (max-width: 768px) {
  .order-details-alt229 {
    flex-direction: column;
  }

  .left-details-alt229,
  .right-details-alt229 {
    margin-bottom: 10px;
  }

  .table-alt229 {
    font-size: 14px;
  }

  .tabs-alt229 {
    flex-wrap: wrap;
  }

  .tab-alt229 {
    flex-grow: 1;
    text-align: center;
  }
}

@media print {
  .container-alt229 {
    max-width: none;
  }

  .header-alt229 {
    display: none;
  }

  .tabs-alt229 {
    display: none;
  }

  .tab-content-alt229 {
    display: block !important;
  }

  .table-alt229 {
    page-break-inside: avoid;
  }
}

/* loading spinner */

.in-spn-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.in-spn-loader-icon {
  height: 2rem;
  width: 2rem;
  animation: in-spn-spin 1s linear infinite;
}

@keyframes in-spn-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.in-spn-loader-text {
  margin-left: 0.5rem;
}

.in-spn-alert {
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
   
}

.in-spn-alert-container{
  padding: 1em;
}
.in-spn-alert-error-content{
  display: flex;
  align-items: center;
  gap: 1em;
}

.in-spn-alert-error {
  background-color: #fee2e2;
  border: 1px solid #f87171;
  color: #991b1b;
}

.in-spn-alert-title {
  margin: 0;

}

.in-spn-alert-description {
  font-size: 1rem;
}



