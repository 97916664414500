.staff-add-new-loan-ctn{
    display: flex;
    flex-direction: column;
    width: 98%;
    padding: 1% 3% ;
    background-color: #fff;
    position: relative;
}

.staff-add-new-loan-ctn-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
}
.staff-add-new-loan-ctn-section-container{
    display: flex;
    justify-content: space-between;
}
.staff-add-new-loan-ctn-section-column{
    width: 32%;
    display: flex;
    flex-direction: column;
}
.staff-add-new-loan-ctn-section-column .global-single-input{
    width: 90%;
    margin-left: 0%;
}
.loan-add-image{
    position: relative;
}
.loan-add-image input{
    width: 40%;
    height: 8vw;
    opacity: 0;
}
.loan-add-image-container{
    width: 30%;
    height: 6vw;
    border: 1px solid rgb(217 217 217 / 80%);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top:15%
}
.loan-add-image-container img{
    width: 92%;
    height: 94%;
}
.staff-add-loan-table{
    margin: 3% 0;
    padding: 2% 0;
}
.staff-add-loan-table tbody tr td{
    font-weight: 600;
    width: 20%;
}
.staff-add-loan-table tbody tr td img{
    width: 10%;
}
.staff-add-new-loan-ctn-bottom{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5% 0;
}
.doc-validation-alert{
    color:red;
    font-size: 0.7rem !important;
    text-align: end;
    position: absolute;
    top: 100%;
    margin: 4px;
    font-weight: 500;
    right: 0%;
}
.file-picker-validation{
    text-align: start !important;
    top: 100% !important;
    left: 0% !important;
}
.validation-parent-input{
    position: relative;
}
.add-loan-clear-btn{
    position: absolute;
    background-color: red;
    right: 0%;
}