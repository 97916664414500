/* OrderDetails.css */
.order-details {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease-in-out;
  .ord-cntr {
    background-color: white;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    padding: 1em;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* border-radius: 8px; */
    border-bottom: 1px solid #bdbdbd;
  }

  .nav-left,
  .nav-right {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    cursor: pointer;
  }

  .orders-text {
    color: #22c55e;
    font-weight: bold;
  }

  .help {
    color: #ef4444;
  }
  .print {
    color: #3b82f6;
  }
  .clock {
    color: #ec4899;
  }

  main {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    position: relative;
  }

  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  .paid-tag {
    background-color: #22c55e;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    /* position: absolute; */
    top: 0;
    right: 0;
    /* transform: translate(30%, -50%) rotate(45deg); */
  }

  .order-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 24px;

    span {
      width: 10em;
      display: inline-block;
    }
  }

  .label {
    font-weight: 600;
  }

  .tabs {
    /* margin-bottom: 16px; */
  }

  .tabs-pos button {
    padding: 8px 16px;
    /* margin-right: 8px; */
    border: none;
    background-color: #e5e7eb;
    cursor: pointer;
    border-bottom: 5px solid #e5e7eb;
  }

  .tabs-pos button.active-tabs {
    background-color: #615d5d;
    color: white;
    border-bottom: 5px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #e5e7eb;
  }

  thead {
    background-color: #f3f4f6;
  }

  tfoot {
    font-weight: bold;
    background-color: #f9fafb;
  }

  .text-right {
    text-align: right;
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
  }

  .summary-item {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 8px;
  }

  .summary-item.total-gsv {
    font-weight: bold;
    border-top: 1px solid #e5e7eb;
    padding-top: 8px;
  }

  .summary-item.amount-due {
    color: #ef4444;
    font-weight: bold;
  }
}

/* general billing create page  */

.billing-new-mdl-container {
  font-family: Arial, sans-serif;
  /* max-width: 1200px; */
  margin: 0 auto;
}
.billing-new-mdl-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.billing-new-mdl-header {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  padding: 10px;
  color: white;
}

.billing-new-mdl-home-icon {
  font-size: 24px;
  margin-right: 10px;
}

.billing-new-mdl-title {
  margin-right: auto;
}

.billing-new-mdl-add-customer,
.billing-new-mdl-view-orders,
.billing-new-mdl-hold-orders {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.billing-new-mdl-search {
  padding: 5px;
  width: 200px;
}

.billing-new-mdl-main {
  /* display: flex; */
  margin-top: 20px;
}

.billing-new-mdl-category-buttons {
  display: flex;
  margin-bottom: 20px;
}

.billing-new-mdl-category-button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}
.billing-new-mdl-content{
  display: flex;
}
.billing-new-mdl-sidebar {
  width: 200px;
  background-color: #f0f0f0;
  padding: 10px;
}

.billing-new-mdl-sidebar-item {
  display: block;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}

.billing-new-mdl-product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
}

.billing-new-mdl-product-item {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.billing-new-mdl-product-item img {
  max-width: 100%;
  height: auto;
}

.billing-new-mdl-cart {
  width: 300px;
  background-color: #f9f9f9;
  padding: 20px;
}

.billing-new-mdl-cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.billing-new-mdl-remove-item {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.billing-new-mdl-subtotal {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-weight: bold;
}

.billing-new-mdl-numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.billing-new-mdl-numpad-key {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  cursor: pointer;
}

.billing-new-mdl-payment,
.billing-new-mdl-hold,
.billing-new-mdl-clear,
.billing-new-mdl-back {
  grid-column: span 3;
  padding: 10px;
  border: none;
  color: white;
  cursor: pointer;
}

.billing-new-mdl-payment {
  background-color: #4caf50;
}
.billing-new-mdl-hold {
  background-color: #ffa500;
}
.billing-new-mdl-clear {
  background-color: #f44336;
}
.billing-new-mdl-back {
  background-color: #2196f3;
}
