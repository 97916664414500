.return-dialog-container{
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    width: 950px;
}
.return-dialog-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.return-p{
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
}
.return-dialog-top-right-container{
    display: flex;
    align-items: center;
}
.return-dialog-top-right-container i{
    color: red;
    font-size: 1.5rem;
    margin: 0 0 0 6px;
    cursor: pointer;
}
.return-dialog-top-right-toggle{
    display: flex;
    margin: 0 20px 0 0;
}
.return-dialog-top-right-toggle p{
    margin: 0;
}
.return-dialog-bottom-container{
    display: flex;
    flex-direction: column;
}
.return-dialog-bottom-one{
    display: flex;
    justify-content: space-between;
    margin: 8px 0
}
.return-dialog-bottom-one p{
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0
}
.return-dialog-bottom-one-radio{
    display: flex;
}
.return-dialog-single-radio{
    margin: 0 20px;
}
.return-dialog-single-radio input{
    accent-color: #000;
    cursor: pointer;
}
.return-dialog-single-radio label{
    cursor: pointer;
    font-size: 0.9rem;
}
.return-dialog-bottom-two{
 display: flex;
 justify-content: space-between;
 margin: 8px 0;
}
.return-dialog-bottom-left{

}
.return-dialog-bottom-left-single{
    display: flex;
}
.return-dialog-bottom-left-single p{
    margin:4px 0;
    font-size: 0.8rem;
}
.return-dialog-bottom-left-p{
  width: 120px;
}
.return-dialog-bottom-right-p{
    margin: 0 0 0 auto !important
}
.return-table{
    padding: 2% 0 !important;
}
.return-table table thead{
    background-color: #f5f4f4;
}
.return-table input[type="text"]{
    background-color: #f5f4f4;
    border: none;
    height: 40px;
    width: 100px;
    padding: 8px;
    text-align: center;
    
}