/* .autocomplete-new-pos-table .MuiAutocomplete-inputRoot .MuiInputBase-input {
    border: none;
    border-bottom: 1px solid #424242;
    height: 8px;
   
  }
  .autocomplete-new-pos-table .MuiOutlinedInput-root{
    padding: 10px 4px !important;
    
  }
  .autocomplete-new-pos-table div.MuiOutlinedInput-root   {
    
   
  } */

  .autocomplete-new-pos-table .MuiOutlinedInput-root,
  .autocomplete-new-pos-table .MuiInputBase-root {
  border: none;
}

.autocomplete-new-pos-table .MuiOutlinedInput-notchedOutline,
/* .autocomplete-new-pos-table .MuiOutlinedInput-input, */
.autocomplete-new-pos-table .MuiInputBase-input {
  border: none;
  outline: none;
}

.autocomplete-new-pos-table .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.autocomplete-new-pos-table .MuiInputBase-root.Mui-focused {
  border: none;
}

.autocomplete-new-pos-table .MuiAutocomplete-inputRoot {
  padding: 0 8px;
}

.autocomplete-new-pos-table .MuiAutocomplete-input {
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 1px solid #000;
  height: 10px;
}
.autocomplete-new-pos-table .MuiOutlinedInput-input{
  border-bottom: 1px solid #000;
  height: 10px;
  padding: 6px;
  margin: 0 4px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
}

.autocomplete-new-pos-table .MuiAutocomplete-endAdornment {
  top: calc(50% - 12px);
}
.border-none .MuiOutlinedInput-input{
  border: none !important;
}


  .thob-form-container{
    display: flex;
    margin: 2% 0;
    /* min-height:40vh; */
    height: 100%;
  }
  .thob-model-left-container{
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    margin: 0 1% 0 0;
    
  }
  .thob-model-right-container{
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    margin: 0 0 0 1%;
    
  }
  .measurment-btn-container{
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 1% 0;
  }
  .update-measurment-btn{
    background-color: rgb(140, 4, 219);
    height: 42px;
    width: 170px;
    border-radius: 3px;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 4px;
    border: none;
    font-weight: 600;
    font-size: 0.8rem;
   
  }
  .reset-measurment-btn{
    background-color: rgb(160, 158, 158);
    height: 42px;
    width: 170px;
    border-radius: 3px;
    cursor: pointer;
    color: #000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 4px;
    border: none;
    font-weight: 600;
    font-size: 0.8rem;
    
  }
  .save-measurment-btn{
    height: 42px;
    width: 170px;
    font-weight: 600;
    font-size: 0.8rem;
  }


  .add-measurment-dialog{
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    width: 450px;
  }
  .add-measurment-dialog h4{
    margin: 1% 0;
  }
.add-measurment-input{
  width: 100%;
  border: 1px solid #ebeefe;
  display: flex;
  align-items: center;
  /* position: relative; */
}
.add-measurment-input input{
  border: none;
  /* flex: 1; */
  text-align: start;
  padding-left: 15px;
  width: 64%;
}
.add-measurment-input input[type="color"]{
  background-color: #fff;
  width: 20%;
  height: 40px;
  cursor: pointer;
}
.add-measurment-input button.btn{
  background-color: #dde1f3;
  color: #000;
  min-width: 100px;
  margin: 0;
  border-radius: 0;
  color: green;
  font-size: 2rem;
}
.add-measurment-input button.btn:hover{
  background-color: #7c89c3;
  color: #504f4f;
}
.add-measurment-dialog-table{
  border:1px solid #eee;
  margin:2% 0;
  max-height:20vh;
  padding: 0;
}
.add-measurment-dialog-table thead{
  height: 40px !important;
}
.add-measurment-dialog-table table tbody tr td{
  height: 40px !important;
}

/* model dialog design */
.add-model-dialog-container{
  width:55vw;
  padding: 4%;
}
.add-model-dialog-container h3{
  margin: 0;
  font-size: 0.9rem;
}
.add-model-dialog-input-container{
  display: flex;
  align-items: center;
  margin: 4% 0;
}
.add-model-popup-table{
  padding: 1%;
  margin: 1%;
  background-color: #fff;
  max-height:45vh;
  overflow-y: scroll;
}
.add-model-popup-table table{
width: 100%;
border-collapse: collapse;
}
.add-model-popup-table table thead{
  position: sticky;
  top: -6px;
  background-color: #fff;
  height: 42px;
  z-index: 2;
}
.add-model-popup-table table thead th{
  border-bottom: 1px solid rgb(238, 232, 232);
  font-size: 0.8rem;
}

.add-model-popup-table table tbody tr td{
  text-align: center;
  font-size: 0.9rem;
  height: 42px;
}
.add-model-popup-table table tbody tr td input[type='checkbox']{
  cursor: pointer;
  accent-color: #000;
}
.add-model-popup-table::-webkit-scrollbar {
  width:4px;
  background-color: #e6e6e6;
}


.add-model-popup-table::-webkit-scrollbar-thumb {
  background-color: #888;
}
.add-model-popup-button-container{
  display: flex;
  justify-content: center;
  margin: 8% 0 2% 0;
}