.single-customer-container{
    display: flex;
}
/* left container */
.single-customer-left-container{
    width: 30%;
}
.single-customer-left-first-container{
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12% 0;
}
.single-customer-left-first-container .image-container{
    /* width: 20%;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.single-customer-left-first-container .image-container img{
    border-radius: 50%;
    /* width: 80px;
    height: 80px */
    width: 96%;
    height: 95px
}
.single-customer-left-first-container .customer-id{
    background-color: #c9d2ff;
    color: #2227c6;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 4px;
    margin:6% 0;
    width: 24%;
    text-align: center;
}
.single-customer-left-first-container .customer-name{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 2% 0 2% 0;
}
.single-customer-left-first-container .phone-number{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
}
.single-customer-left-first-container .customer-mail{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 2% 0;
}
.single-customer-left-first-container .divider{
    border-bottom: 1px solid rgb(223, 219, 219);
    width: 100%;
    margin: 4% 0;
}
.single-customer-left-first-container .joining-date{
    font-size: 0.75rem;
    font-weight: 600;
    margin: 1% 0;
}
.single-customer-left-first-container button{
    background-color: #c9ffe4;
    color:#0b997e;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
    padding:1% 2%;
    margin: 4% 0 0 0;
    border-radius: 2px;
}

.single-customer-left-second-container{
    background-color: #fff;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8% 0;
}
.single-customer-left-second-container .reward-points{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 3% 0;
}
.single-customer-left-second-container .point{
    background-color:#fbbfbf;
    color:#ff0000;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 4px;
    width: 24%;
    text-align: center;
    margin: 3% 0;
    padding: 1% ;
    
}

/* right container */
.single-customer-right-container{
    background-color: #fff;
    width: 70%;
    border-radius: 4px;
    margin: 0 1%;
    padding:2%;
}
.single-customer-right-top-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.single-customer-right-top-right-container{
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.single-customer-right-top-right-container .print-icon{
    background-color:#fff1f1;
    color: #ff0000 !important;
    border-radius: 2px;
    font-size: 1.3rem;
    width: 16%;
}
.single-customer-right-top-right-container .download-icon{
    background-color: #f9f1ff;
    color: #c164ff;
    border-radius: 2px;
    font-size: 1.3rem;
    width: 16%;
}


.customer-single-status{    
    color: #fff;
    border-radius: 16px;
    font-size: 0.7rem;
    font-weight: 600;
    width: 75%;
    height: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status-completed{
    background-color: #b8ebd7;
    color: #28b69e 
}
.status-cancelled{
    background-color: #c20006;
    color: #ffffff;
}
.status-returned{
    background-color: #ecacae;
    color: #e4394d;
}
.status-pending{
    background-color:#ecc442;
    color: #fff;
}
.status-delivery{
    background-color: #cfd4e6;
    color: #0a33fe;
}
.status-delivered{
    background-color: #052bffeb;
    color: #f0ffed;
}
.status-printing{
    background-color: #cfe4e5;
    color: #2ab5ca;
}
.customer-rating-star-icon .css-iljtu2-MuiRating-root{
    color: #2227c6;
    font-size: 1.8rem;
}
.customer-rating-star-icon .css-1c99szj-MuiRating-icon{
    color: #2227c6;
}
.total-credit-star-icon .MuiRating-root{
    color: red;
    font-size: 1.4rem;
}
.total-credit-star-icon .MuiRating-icon{
    color: red;
    
}
.credit-points-customer{
    color: red;
    font-size: 0.8rem;
    margin: 4px 0;
    font-weight: 600;
}
.order-points-customer{
    color:green;
    font-size: 0.8rem;
    margin: 4px 0;
    font-weight: 600;
}
.total-order-star-icon .MuiRating-root{
    color: green;
    font-size: 1.4rem;
}
.total-order-star-icon .MuiRating-icon{
    color:green;
    
}



/* add relationship dialog style */
.add-relationship-dialog{
    width: 62vw;
    padding: 2%;
}
.add-relationship-dialog-top-container{
    background-color: #f6f6f6;
    padding: 1% 2%;
    margin: 0 0 2% 0;
}
.add-relationship-dialog-top-heading-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-relationship-dialog-top-heading-close-icon-container{
    border-radius: 50%;
    border: 1px solid red;
    background-color:#ffe1e1;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.add-relationship-dialog-top-left-container{
    width: 55%;
}
.add-relationship-dialog-top-right-container{
width: 40%;
}
.add-relation-name-content-single-container{
    display: flex;
    width: 100%;
}

.add-relation-name-content-single-container .name{
    width: 16%;
    margin: 1% 0;
}
.add-relation-name-content-single-container h4{
    margin: 2% 0;
   
}
.add-relation-name-content-single-container p{
    margin: 1% 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.add-relationship-dialog-input-container{
    display: flex;
    flex-wrap: wrap;
}

.auto-complete-name .MuiAutocomplete-endAdornment{
    display: none;
}
.auto-complete-name .MuiInputBase-input{
    background-color: #fff !important;
    /* height: 0; */
}
.auto-complete-name .MuiOutlinedInput-root{
    background-color: #fff !important;
}
.auto-complete-freesolo .MuiInputBase-input{
    background-color: #fff !important;
    height: 0;
}
.auto-complete-freesolo .MuiOutlinedInput-root{
    background-color: #fff !important;
}


.single-ustomer-new-points-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 4%;
}
.single-customer-couts-container{
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 2%;
    justify-content: end;
}
.single-customer-couts-container h5{
   font-size: 0.75rem;
   font-weight: 600;
   margin:4px 0;
   text-align: center;
}
.single-customer-couts-container p{
    margin: 0;
    background-color: #edf2f9;
    border-radius: 4px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}