.discount-settings-container{
    display: flex;
    
}
.discount-settings-container-body{
    display: flex;
    flex-direction: column;
    width:70%;
}
.discount-settings-container-body-section{
    display: flex;
    width: 100%;
    padding: 40px 30px;
    background-color: #fff;
    margin: 0 1%;
    flex-direction: column;
    border-radius: 4px;
    
}
.discount-settings-container-body p{
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}
.discount-settings-container-body input{
    width: 98%;
    height: 60px;
    margin: 2% 0;
    background-color:#f6f6f6;
    border: none;
    font-size: 1rem;
    padding: 0 16px;
}
.discount-settings-container-button{
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 2%;
}
.discount-settings-container-button button{
    width: 20%;
}

.discount-all-branch-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px
}
.discount-all-branch-checkbox input[type="checkbox"]{
    width: 20px;
}
.discount-all-branch-checkbox label{
    font-size: 0.8rem;
    margin-left: 8px;
}
.branch-list-container{

}
.branch-list-container-top{
    display: flex;
}
.branch-list-container-top p {
    width: 30%;
    text-align: center;
}
.branch-list-container-bottom{
    max-height: 66vh;
    overflow: auto;
}
.branch-list-container-bottom::-webkit-scrollbar{
    width: 4px;
    background-color: #f1f1f1;
}
.branch-list-container-bottom::-webkit-scrollbar-thumb{
    background-color: #c1c1c1;
}
.branch-list-container-bottom p{
    font-size: 0.75rem;
}
.branch-list-container-bottom input{
    width: 30%;
    height: 30px;
    background-color: #fff;
    border: 1px solid #aaa2f9;
    border-radius: 4px ;
    margin-left: 4px;
    text-align: center;
}