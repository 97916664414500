.button-div{
    padding: 46px 0px 0px 0px;
}
.button-div button{
    padding: 12px;
    border: none;
    padding-inline: 20px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
}
.selected-btn {
    background-color: rgb(139, 138, 138);
    border-bottom: 3px solid black!important;
  
  }

  /* work order tabs css */
  .work-order-tab-table{
    max-height: 35vh;
    margin: 0 0 0 17px;
    padding: 0;
}
.work-order-tab-table thead{
    background-color: #e6e6e6 !important;
}
.work-order-tab-table tbody tr td{
    height: 40px !important;
}
.work-order-tab-amount-container{
    display: flex;
    justify-content: end
}

.work-order-tab-table tfoot {
    position: sticky;
    bottom: 0;
    background-color: #e6e6e6;
    z-index: 1;
    height: 40px;
    text-align: center;
}

.work-order-tab-table tfoot .total-row th, tfoot .total-row td {
    font-weight: 600;
    font-size: 0.9rem;
}

.round-circle-job-completion{
   font-size: 1rem;
}